.labelProcediment {
    font-size: 18px;
    color: #444444;
    font-weight: bold;
    /* text-transform: uppercase; */
  }
  
  .textProcediment {
    background-color: rgb(0, 0, 0, 5%);
    border-radius: 12px;
    padding-top: 20px;
    padding-bottom: 10px;
  
    padding-right: 20px;
    max-width: fit-content;
    font-size: 1rem;
    color: #212529 !important;
    font-weight: normal;
  }
  
  .textProcediment a {
    color: #9e003c !important;
    text-decoration: none;
  }
  
  .textProcediment a:hover {
    color: #444444 !important;
    text-decoration: underline;
  }
  
  .llistaMapa{
      list-style-type: circle;
  }
  
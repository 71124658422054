.cercadorNoticies {
  border-top-left-radius: 15px !important;
  border-bottom-left-radius: 15px !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;

  padding-left: 15px !important;
  background-color: #efefef !important;
  color: #929292;
  border-right: none !important;
}

.cercadorNoticies:focus {
  box-shadow: none !important;
  border: 1px solid #ced4da !important;
}

.btnCercadorNoticies {

  background-color: #efefef !important;
  color: #929292;
  border-left: none !important;
  border-top-right-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
  border-top: 1px solid #ced4da !important;
  border-bottom: 1px solid #ced4da !important;
  border-right: 1px solid #ced4da !important;
}

.btnCercadorNoticies:focus {
  box-shadow: none !important;
}

.titolResultats{
  font-size: 16px !important;
}
.header {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  background-color: transparent !important;
  z-index: 2000;
  width: 100%;
  height: 200px;
}

.nav-top {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  width: 500px;
}

.navLinks {
  display: inline-grid !important;
}
.linkHeader {
  text-decoration: none !important;
  margin-top: 15px !important;
  
}
.header .nav-item {
  margin-right: 0.5rem !important;
}

.header .especialMarge {
  margin-right: 0 !important;
}

.navbar-brand {
  padding: 0 !important;
}

.nav .nav-link {
  color: white !important;
  font-size: 16px;
  padding-right: 0px;
  padding-left: 6px;
  padding: 0 !important;
}

.nav .nav-link.active {
  color: #9e003c !important;
}

.nav .nav-link:hover {
  text-decoration: underline;
  color: #9e003c !important;
}

.links {
  background-color: transparent !important;
  border: none !important;
  padding-right: 0 !important;
  padding-left: 0 !important;
  color: white !important;
}

.navPortals:hover {
  color: white !important;
  text-decoration: underline !important;
}

.navPortals {
  color: white !important;
  text-decoration: none !important;
  background-color: #9e003c;
  border-radius: 12px;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 10px !important;
}

.groupButtons {
  justify-content: flex-end !important;
  margin-right: 20px !important;
  margin-top: 10px !important;
  color: white !important;
}

/* @media (max-width: 535px) {
  .navLinks {
    display: inline !important;
  }
} */

.page-item.active .page-link {
    color: #fff !important;
    background-color: #efefef !important;
    border-color:#dee2e6  !important;
    color: #9e003c !important;
 
}

.page-link{
  
    color: #444444 !important;
}

.page-link:focus{
    box-shadow: none !important;  
}
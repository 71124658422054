.shadows {
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.25), 0 3px 20px 11px rgba(0, 0, 0, 0) !important;
}

.form-control:focus {
  border-color: #7e0030 !important;
  box-shadow: 0 0 0 0.25rem rgb(173 38 89 / 50%) !important;
}
.errorForm {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

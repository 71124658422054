.card .card-link {
    color: #9e003c !important;
    font-size: 16px;
    padding-right: 0px;
    text-decoration: none;
}

.cardNoticia{
    border-radius: 3% !important;
}

.subtitolNoticia{
    font-size: 14px;
}

.titolNoticia{
    font-size: 18px;
}

.textNoticia{
    font-size: 14px;
}
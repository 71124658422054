.react-multi-carousel-dot-list {
    position: initial !important;
    padding-bottom: 0.25rem !important;
  }
  
  .bordesActualidad {
    /* border-right: 1px solid #9e003c;
    border-bottom-left-radius: 5%;
    border-bottom-right-radius: 5%;
  
    border-left: 1px solid #9e003c;
  
    border-bottom: 1px solid #9e003c; */
  }
  
  .titolAct {
    text-align: left !important;
    margin-left: 16px;
    color: white !important;
    padding-bottom: 2px;
  }
  
  .react-multi-carousel-dot--active button {
    background: grey !important;
  }
  
  .titolCard {
    text-transform: uppercase;
    color: white;
    background-color: #9e003c;
  }
  
  .cardInformes {
    text-align: center !important;
    border-radius: 7% !important;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.25), 0 3px 20px 11px rgba(0, 0, 0, 0) !important;
  }
  .card-title {
    margin-bottom: 0 !important;
  }
  
  
  .carouselNoticiesLink {
    color: #212529 !important;
    text-decoration: none;
    padding-bottom: 2px;
  }
  
  .carouselNoticiesLink:hover {
    color: #9e003c !important;
    text-decoration: underline;
  }
  
.titolsEnllasos {
  background: rgb(211, 211, 211);
  background: linear-gradient(
    90deg,
    rgba(211, 211, 211, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  padding: 5px;
  padding-left: 14px;
  color: black;
  border-radius: 7px;
}

.titolEnllas {
  margin-top: 8px;
}
.icon {
  color: rgba(148, 148, 148, 1) !important;
}

.linksEnllasos {
  color: #9e003c !important;
  text-decoration: none;
  padding-bottom: 2px;
}

.linksEnllasos:hover {

  color: #212529 !important;
  text-decoration: underline;
}


.list{
    list-style: none;
}
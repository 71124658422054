
$theme-colors: (
    "primary": #9e003c,
    "danger": #ff4136,
    "warning": #c45913,
    "info" : #4468a0,
    "secondary": #c1205e,
  
  );

  @import "node_modules/bootstrap/scss/bootstrap";


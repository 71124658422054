.titolsArees {
    background-color: #d7d7d7 !important;
    /* background: linear-gradient(
      90deg,
      rgba(158, 0, 60, 1) 0%,
      rgba(255, 255, 255, 1) 100%
    ); */
    padding: 5px;
    padding-left: 14px;
    color: black;
    border-radius: 7px;
  }

  .llistaSubseccions{
      list-style: none !important;
     
  }

  .titolSubSeccio{
    background-color: #efefef !important;
    padding: 12px;
    padding-left: 14px;
    border-radius: 7px;
  
  }

  .llistaArea{
    list-style-type: circle;
}

.descripcioSeccio{
  font-size: 16px !important;
}

.linkAreaDocument {
  color: #9e003c !important;
  text-decoration: none;
  border: none;
  background-color: white;
  padding-left: 0 !important;
  text-align: initial;
}

.linkAreaDocument:hover {
  color: #212529 !important;
  text-decoration: underline;
}